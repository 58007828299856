<template>
  <div>
    <div v-if="code === ''" class="anquan-box">
      <div class="anquan-img">
        <img :src="anquanPng" />
      </div>
      <div class="anquan-text">
        <div class="tui-loading-row">
          <div class="tui-loading-cell">
            <div class="circle-line">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div class="tip">正在验证您的身份</div>
      </div>
      <div class="footer">
        <div>石家庄掌讯信息技术有限公司 提供技术支持</div>
        <div>© 2010 - {{new Date().getFullYear()}} all rights reserved</div>
      </div>
    </div>
    <div class="anquan-box" v-if="code === 0">
      <div class="success-img">
        <img :src="successPng" />
      </div>
      <div class="anquan-text">
        <div class="tip" style="margin: 2rem 0">验证通过</div>
      </div>
      <div class="footer">
        <div>石家庄掌讯信息技术有限公司 提供技术支持</div>
        <div>© 2010 - {{new Date().getFullYear()}} all rights reserved</div>
      </div>
    </div>
    <div class="validation-failed-box" v-if="code === 407">
      <img class="failed-img" :src="failedPng" />
      <div class="validation-failed-text">安全验证失败</div>
      <div class="login" @click="bindWxInfoApi">重新验证</div>
      <div class="customer_service" @click="goService">咨询客服</div>
      <div class="footer">
        <div>石家庄掌讯信息技术有限公司 提供技术支持</div>
        <div style="margin-top: 5rpx">© 2010 - {{new Date().getFullYear()}} all rights reserved</div>
      </div>
    </div>
  </div>
</template>
<script>
import wx from 'weixin-js-sdk'
import { getUrlKey } from '@/libs/utils'
import { bindWxInfo } from '@/apis/api'
import anquanPng from '@/assets/image/anquan.png'
import failedPng from '@/assets/image/icon_validationfailed.png'
import successPng from '@/assets/image/icon_succcess.png'
export default {
  name: 'get-wx-code',
  data(){
    return{
      anquanPng,
      failedPng,
      successPng,
      code: ''
    }
  },
  created() {
    setTimeout(()=>{
      this.bindWxInfoApi()
    },3000)
  },
  methods: {
    bindWxInfoApi(){
      this.code = ''
      let code = getUrlKey("code")? getUrlKey("code"):''
      let state = getUrlKey("state")? getUrlKey("state"):''
      let params = {
        code,
        state,
      }
      bindWxInfo(params).then(res => {
        console.log(res)
        this.code = res.code
        //4804 门店联系人不存在 406经销商未配置小程序信息 407绑定公众号openid失败
        if(res.code === 0){
          setTimeout(()=>{
            wx.miniProgram.redirectTo({url: "/pages/webview/backpage?code="+res.code})
          },1000)
        }else if(res.code === 4804 || res.code === 406 || res.code === 1){
          wx.miniProgram.redirectTo({url: "/pages/login/error"})
        }else if(res.code === 434 == res.code === 435){
          this.$toast(res.msg)
          setTimeout(()=>{
            wx.miniProgram.redirectTo({url: "/pages/login/index"})
          },1000)
        }
        //跳转回小程序的登录页面并将公众号openid带过去
      }).catch(err =>{
        wx.miniProgram.redirectTo({url: "/pages/login/error"})
      })
    },
    goService(){
      wx.miniProgram.redirectTo({url: "/pages/service/index"})
    }
  }
}
</script>
<style scoped>
.anquan-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  background: #FFFFFF;
}
.tip{
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #3C3C3C;
  font-weight: bold;
}
.footer {
  position: fixed;
  bottom: 12px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #3C3C3C;
  font-weight: 400;
  line-height: 24px;
}
.tui-loading-row{
    width: 100%;
    display: table;
    table-layout: fixed;
}
.tui-loading-cell{
  width: 100%;
  display: table-cell;
  text-align: center;
}
/*动画一：单个渐变  */
.circle-line{
    width: 60px;
    height: 60px;
    display: inline-block;
    position: relative;
    animation: circle1 1.5s linear infinite; 
}
.circle-line span{
    display: block;
    width: 50%;
    height: 5px;
    opacity: .7;
    position: absolute;
    top: calc(50% - 2.5px);
    left: 0px;
    transform-origin: center right; 
}
.circle-line span::before{
    content: '';
    display: block;
    width:8px;
    height:8px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 5px;
    background-color: rgb(255, 130, 0);
    animation: setsize 1.5s linear infinite; 
}
.circle-line span:nth-child(1){
    transform: rotate(8deg);
    animation-delay: 0.2s;
    opacity: 0.8;
}
.circle-line span:nth-child(2){
    transform: rotate(98deg);
    animation-delay: 0.4s;
    opacity: 0.6;
}
.circle-line span:nth-child(3){
    transform: rotate(188deg);
    animation-delay: 0.6s;
    opacity: 0.4;
}
.circle-line span:nth-child(4){
    transform: rotate(278deg);
    animation-delay: 0.8s;
    opacity: 0.2;
}
.anquan-img img{
  margin-top: -100px;
  width: 108px;
  height: 120px;
  animation: imgSetBig .5s ease-out	1; 
}

.anquan-text{
  font-size: 16px;
  opacity: 1;
  transform: translate(0, 0);
  animation: textSetOpacity .5s ease-out 1; 
}
.success-img img{
  width: 135px;
  height: 150px;
}
@keyframes imgSetBig {
    0%{
      margin-top: 0px;
        width:135px;
        height:150px;
    }
    80%{
        margin-top: 0px;
        width:135px;
        height:150px;
    }
    100%{
        margin-top: -100px;
        width:108px;
        height:120px;
    }
}
@keyframes textSetOpacity {
    0%{
        opacity: 0;
        transform: translate(180, 0);
    }
    80%{
        opacity: 0;
        transform: translate(180, 0);
    }
    100%{
        transform: translate(0, 0);
        opacity: 1;
    }
}
@keyframes setsize {
    0%{
        width:4px;
        height:4px;
    }
    25%{
        width:6px;
        height:6px;
    }
    50%{
        width:8px;
        height:8px;
    }
    75%{
        width:6px;
        height:6px;
    }
    100%{
        width:4px;
        height:4px;
    }
}
@keyframes circle1 {
    0%{
      transform:rotate(0deg);
      -ms-transform:rotate(0deg); 	/* IE 9 */
      -moz-transform:rotate(0deg); 	/* Firefox */
      -webkit-transform:rotate(0deg); /* Safari 和 Chrome */
      -o-transform:rotate(0deg);
    }
    25%{
      transform:rotate(72deg);
      -ms-transform:rotate(72deg); 	/* IE 9 */
      -moz-transform:rotate(72deg); 	/* Firefox */
      -webkit-transform:rotate(72deg); /* Safari 和 Chrome */
      -o-transform:rotate(72deg);
    }
    50%{
      transform:rotate(144deg);
      -ms-transform:rotate(144deg); 	/* IE 9 */
      -moz-transform:rotate(144deg); 	/* Firefox */
      -webkit-transform:rotate(144deg); /* Safari 和 Chrome */
      -o-transform:rotate(144deg); 
    }
    75%{
      transform:rotate(216deg);
      -ms-transform:rotate(216deg); 	/* IE 9 */
      -moz-transform:rotate(216deg); 	/* Firefox */
      -webkit-transform:rotate(216deg); /* Safari 和 Chrome */
      -o-transform:rotate(216deg); 
    }
    100%{
      transform:rotate(360deg);
      -ms-transform:rotate(360deg); 	/* IE 9 */
      -moz-transform:rotate(360deg); 	/* Firefox */
      -webkit-transform:rotate(360deg); /* Safari 和 Chrome */
      -o-transform:rotate(360deg); 
    }
}

.validation-failed-box{
  display: flex;
		flex-direction: column;
		align-items: center;
		height: 100vh;
		justify-content: center;
		background: #FFFFFF;
}
/* 失败 */
  .failed-img{
    width: 100%;
    height: 11rem;
  }
  .validation-failed-text{
    margin-top: 2rem;
    margin-bottom: .5rem;
    font-size: 14px;
    width: 100%;
    text-align: center;
    color: #3C3C3C;
    font-weight: bold;
  }
	.login {
		display: flex;
		justify-content: space-around;
		margin-top: 50px;
		width: 15rem;
		line-height: 2.5rem;
		border-radius: 50px;
		font-size: .8rem;
		color: #FFFFFF;
		margin: 1rem 0 .5rem 0;
		background: linear-gradient(90deg, rgba(255, 200, 13, 1) 0%, rgba(255, 130, 0, 1) 100%);
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	}
	
	.customer_service {
		display: flex;
		justify-content: space-around;
		width: 15rem;
		line-height: 2.5rem;
		border-radius: 50px;
		font-size: .8rem;
		color: #FFFFFF;
		background: linear-gradient(90deg, rgba(255, 87, 43, 1) 0%, rgba(255, 0, 23, 1) 100%);
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	}
</style>